import {
  faCalendarCheck,
  faCircleCheck,
  faCircleXmark,
  faClipboardCheck,
  faExternalLink,
  faFileExcel,
  faHardHat,
  faQrcode,
  faScanner,
  faSign,
  faTrash,
  faUsers,
} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  ListSubheader,
  MenuItem,
  Switch,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";
import accounting from "accounting";
import classnames from "classnames";
import {groupBy, keyBy} from "lodash";
import moment from "moment-timezone";
import React from "react";
import {Helmet} from "react-helmet";
import {useQueryState} from "use-location-state";
import HtmlRender from "../../../../js/components/HtmlRender";
import {unslugify} from "../../../../js/utils/string";
import {axiosAPI} from "../../../api";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {ColoredButton, DropdownButton} from "../../../components/Buttons";
import {ConfirmationDialog} from "../../../components/Dialogs";
import {FilterDate} from "../../../components/Filters";
import {StatusIcon} from "../../../components/Icons";
import {LabelValue} from "../../../components/LabelValue";
import {LegacyUILink, MuiNavLink} from "../../../components/Links";
import {PageHeader} from "../../../components/PageHeader";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import {PreviousNextDate} from "../../../components/PreviousNextDate";
import {ReactTableMui} from "../../../components/ReactTableMui";
import FormActions from "../../../components/forms/FormActions";
import useBlockUI from "../../../hooks/useBlockUI";
import {useFetchCurrentPage, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import useWaffle from "../../../hooks/useWaffle";
import {makeNovoClasses} from "../../../theme";
import {colorError, colorMutedText, colorSuccess} from "../../../theme/colors";
import {PretaskPlanDialogForm} from "../ProjectPretaskPlans/PretaskPlanForms";
import {TwilioContactDialogForm} from "./ProjectDailyCheckInForms";

const useStyles = makeStyles((theme) => {
  const {transitions, breakpoints, palette, shape, typography} = theme;
  return {
    response: {
      "& p": {
        marginBottom: 0,
      },
    },
  };
});

const ProjectDailyCheckIns = (props) => {
  const {project: project, user, userContact, ...rest} = props;
  const [scanLog, setScanLog] = React.useState([]);
  const [scanLogIsFetching, setScanLogIsFetching] = React.useState(false);
  const [currentCheckIn, setCurrentCheckIn] = React.useState({} as any);
  const [currentPretaskPlan, setCurrentPretaskPlan] = React.useState({} as any);
  const [showCheckInDialog, setShowCheckInDialog] = React.useState(false);
  const [showTwilioContactDialog, setShowTwilioContactDialog] = React.useState(false);
  const [showPretaskPlanDialog, setShowPretaskPlanDialog] = React.useState(false);
  const [showProjectQRCodeDialog, setShowProjectQRCodeDialog] = React.useState(false);
  // const [showVerifyVaccineStatusConfirmation, setShowVerifyVaccineStatusConfirmation] = React.useState(false);
  const [showResetCheckInConfirmation, setShowResetCheckInConfirmation] = React.useState(false);
  const [showUnlockDialog, setShowUnlockDialog] = React.useState(false);
  const [sortByCompany, setSortByCompany] = React.useState(true);
  const [auditConfirmationIsOpen, setAuditConfirmationIsOpen] = React.useState(false);
  const [auditedFormValues, setAuditedFormValues] = React.useState({});
  const [reviseResubmitDialogIsOpen, setReviseResubmitDialogIsOpen] = React.useState(false);

  const [date, setDate] = useQueryState("date", moment().format("YYYY-MM-DD"));
  const blockUI = useBlockUI();
  const novoClasses = makeNovoClasses();
  const classes = useStyles();
  const waffle = useWaffle();

  // const pretaskPlanMinDate = moment(date).subtract(7, "d").format("YYYY-MM-DD");

  const pretaskPlanMinDate = moment(date).format("YYYY-MM-DD");

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      twilio_contacts_with_orientations: [],
    },
  });

  const pageData = pageDataQuery.data;

  const {query: checkInsQuery, rpc: checkInsRPC} = useSentinelListAPI(
    `projects/${project.id}/twilioapp/wellness-check-results/?compliance_date=${date}&ordering=-created`,
    {
      initialData: {
        results: [],
      },
      keepPreviousData: true,
    }
  );
  const checkIns = checkInsQuery.data.results;

  const {
    query: pretaskPlanQuery,
    update: updatePretaskPlan,
    rpc: pretaskPlanRPC,
  } = useSentinelListAPI(
    `projects/${project.id}/superintendents/pretask-plans/?page_size=300&date_min=${pretaskPlanMinDate}&date_max=${date}&ordering=-created`,
    {
      initialData: {
        results: [],
      },
      // https://react-query.tanstack.com/guides/paginated-queries
      keepPreviousData: true,
    }
  );
  const pretaskPlans = pretaskPlanQuery.data.results;

  const {
    query: foremenQuery,
    create: createForemen,
    delete: deleteForemen,
  } = useSentinelListAPI(
    `projects/${project.id}/foremen/`,
    {
      initialData: {
        results: [],
      },
      keepPreviousData: true,
    },
    {
      idProp: "uuid",
    }
  );
  const foremen = foremenQuery.data.results;

  const foremenUUIDs = foremen.map((forman) => forman.uuid);

  const checkinCompanies = groupBy(checkIns, "twilio_contact.fuzzy_company.name");

  const canUnlock =
    ["submitted", "audited"].includes(currentPretaskPlan.status) &&
    !currentPretaskPlan.is_unlocked &&
    currentPretaskPlan.is_closed;

  const pretaskPlanLookup = keyBy(pretaskPlans, "twilio_contact.number");
  const reviseResubmitContactEmail =
    currentPretaskPlan?.survey?.contact?.email ?? currentPretaskPlan?.twilio_contact?.email;

  const {twilio_contacts_with_orientations} = pageData;

  return (
    <>
      <Helmet title={`${project.display} - Daily Check Ins`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Check Ins</Typography>
      </ProjectBreadcrumbs>

      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>
            <FontAwesomeIcon icon={faCalendarCheck} /> {moment.tz(date, project.timezone).format("dddd, MMMM Do")}
          </PageHeader.Title>
        </PageHeader.Left>
      </PageHeader>
      <Box mb={1} />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box alignItems="center" display="flex">
            <Box flexGrow={1} mr={1}>
              <FilterDate
                label="Date"
                value={date}
                required
                onChange={(date) => {
                  if (date) {
                    setDate(date);
                  }
                }}
              />
            </Box>
            <Box>
              <PreviousNextDate date={date} setDate={setDate} />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={6} sm={3}>
          <Button
            size="small"
            variant="outlined"
            fullWidth
            component={MuiNavLink}
            href={`/v2/projects/${project.id}/manual-check-ins/#date=${date}`}
            startIcon={<FontAwesomeIcon icon={faClipboardCheck} />}
            underline="none"
          >
            Manual Check Ins
          </Button>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Button
            size="small"
            variant="outlined"
            fullWidth
            href={`/superintendents/covid/scan/`}
            startIcon={<FontAwesomeIcon icon={faScanner} />}
          >
            Scan App
          </Button>
        </Grid>
        <Grid item xs={6} sm={3}>
          <DropdownButton size="small" title="Signs" fullWidth startIcon={<FontAwesomeIcon icon={faSign} />}>
            {(setOpen) => (
              <div>
                <ListSubheader>English</ListSubheader>
                <MenuItem>
                  <Link href={`/projects/${project.id}/covid/checkin/sign/8.5x11/`} underline="none" target="_blank">
                    8.5x11
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link href={`/projects/${project.id}/covid/checkin/sign/11x17/`} underline="none" target="_blank">
                    11x17
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link href={`/projects/${project.id}/covid/checkin/sign/24x36/`} underline="none" target="_blank">
                    24x36
                  </Link>
                </MenuItem>
                <ListSubheader>Spanish</ListSubheader>
                <MenuItem>
                  <Link
                    href={`/projects/${project.id}/covid/checkin/sign/8.5x11/?lang=esp`}
                    underline="none"
                    target="_blank"
                  >
                    8.5x11
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link
                    href={`/projects/${project.id}/covid/checkin/sign/11x17/?lang=esp`}
                    underline="none"
                    target="_blank"
                  >
                    11x17
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link
                    href={`/projects/${project.id}/covid/checkin/sign/24x36/?lang=esp`}
                    underline="none"
                    target="_blank"
                  >
                    24x36
                  </Link>
                </MenuItem>
              </div>
            )}
          </DropdownButton>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Button
            size="small"
            variant="outlined"
            fullWidth
            startIcon={<FontAwesomeIcon icon={faQrcode} />}
            onClick={() => {
              setShowProjectQRCodeDialog(true);
            }}
          >
            Project QR Code
          </Button>
        </Grid>
      </Grid>

      <Box mb={1} />

      <PaperPanel>
        <PaperPanel.Header isLoading={checkInsQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faUsers} /> Site Roster{" "}
            {checkInsQuery.data?.count && (
              <Chip size="small" label={accounting.formatNumber(accounting.formatNumber(checkInsQuery.data?.count))} />
            )}
          </PaperPanel.Header.Title>

          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <Switch onChange={() => setSortByCompany(!sortByCompany)} checked={sortByCompany} />
              Group By Company
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                href={`/v2/projects/${project.id}/check-ins/excel/`}
              >
                Export Excel Log
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.PDFButtons
                pdfURL={`/reports2/projects/${project.id}/covid/checkin/?start_date=${date}&end_date=${moment(date)
                  .add(7, "d")
                  .format("YYYY-MM-DD")}`}
                buildReportURL={`/reports2/projects/${
                  project.id
                }/covid/checkin/filter/?start_date=${date}&end_date=${moment(date).add(7, "d").format("YYYY-MM-DD")}`}
              />
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => Promise.all([checkInsQuery.refetch(), pageDataQuery.refetch()])}
                isFetching={checkInsQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          {!sortByCompany
            ? checkIns
                .sort((a, b) => Date.parse(b.checkin_timestamp) - Date.parse(a.checkin_timestamp))
                .map((checkIn) => {
                  const isForeman = foremenUUIDs.includes(checkIn.twilio_contact.uuid);
                  return (
                    <CheckinPaperItem
                      project={project}
                      checkIn={checkIn}
                      isForeman={isForeman}
                      onClickContact={() => {
                        setCurrentCheckIn(checkIn);
                        setShowCheckInDialog(true);
                      }}
                      onClickForeman={() => {
                        if (!isForeman) {
                          createForemen.mutateAsync({uuid: checkIn.twilio_contact.uuid});
                        } else {
                          deleteForemen.mutateAsync(checkIn.twilio_contact.uuid);
                        }
                      }}
                      // onClickVaccine={() => {
                      //   setCurrentCheckIn(checkIn);
                      //   setShowVerifyVaccineStatusConfirmation(true);
                      // }}
                      key={checkIn.id}
                      pretaskPlan={pretaskPlanLookup[checkIn.twilio_contact.number]}
                      onClickPretaskPlan={() => {
                        setCurrentPretaskPlan(pretaskPlanLookup[checkIn.twilio_contact.number]);
                        setShowPretaskPlanDialog(true);
                      }}
                      sortByCompany={sortByCompany}
                      hasSafetyOrientation={twilio_contacts_with_orientations.includes(checkIn.twilio_contact.number)}
                    />
                  );
                })
            : Object.keys(checkinCompanies)
                .sort()
                .map((company) => {
                  return (
                    <React.Fragment key={company}>
                      <PaperItem.Header>
                        {company === "undefined" ? (
                          <React.Fragment key={company}>
                            <strong>Unmatched</strong> - These check-ins could not be matched to a company in Sentinel.
                          </React.Fragment>
                        ) : (
                          // <MuiNavLink href={companyLookup[company].twilio_contact.fuzzy_company.url} underline="always">
                          <Box display="flex" alignItems="center">
                            <Box mr={1}>
                              <strong>{company}</strong>
                            </Box>
                            <Chip size="small" label={accounting.formatNumber(checkinCompanies[company].length)} />
                          </Box>
                          // </MuiNavLink>
                        )}
                      </PaperItem.Header>

                      {checkinCompanies[company]
                        .sort((a, b) => Date.parse(b.checkin_timestamp) - Date.parse(a.checkin_timestamp))
                        .map((checkIn) => {
                          const isForeman = foremenUUIDs.includes(checkIn.twilio_contact.uuid);
                          return (
                            <CheckinPaperItem
                              project={project}
                              checkIn={checkIn}
                              isForeman={isForeman}
                              onClickContact={() => {
                                setCurrentCheckIn(checkIn);
                                setShowCheckInDialog(true);
                              }}
                              onClickForeman={() => {
                                if (!isForeman) {
                                  createForemen.mutateAsync({uuid: checkIn.twilio_contact.uuid});
                                } else {
                                  deleteForemen.mutateAsync(checkIn.twilio_contact.uuid);
                                }
                              }}
                              // onClickVaccine={() => {
                              //   setCurrentCheckIn(checkIn);
                              //   setShowVerifyVaccineStatusConfirmation(true);
                              // }}
                              key={checkIn.id}
                              pretaskPlan={pretaskPlanLookup[checkIn.twilio_contact.number]}
                              onClickPretaskPlan={() => {
                                setCurrentPretaskPlan(pretaskPlanLookup[checkIn.twilio_contact.number]);
                                setShowPretaskPlanDialog(true);
                              }}
                              sortByCompany={sortByCompany}
                              hasSafetyOrientation={twilio_contacts_with_orientations.includes(
                                checkIn.twilio_contact.number
                              )}
                            />
                          );
                        })}
                    </React.Fragment>
                  );
                })}
        </PaperPanel.Body>
      </PaperPanel>

      <Dialog
        open={showCheckInDialog}
        onClose={() => {
          setShowCheckInDialog(false);
          setScanLog([]);
        }}
        maxWidth="lg"
        fullWidth
        TransitionProps={{
          onEnter: () => {
            setScanLogIsFetching(true);
            axiosAPI
              .get(
                `projects/${project.id}/twilioapp/wellness-check-results/${currentCheckIn.id}/scan-log/?page_size=1000`
              )
              .then((response) => {
                setScanLog(response.data.results);
                setScanLogIsFetching(false);
              })
              .catch(() => {
                setScanLogIsFetching(false);
              });
          },
        }}
      >
        <DialogTitle id="form-dialog-title">
          Check-in: {currentCheckIn.compliance_date} - {currentCheckIn.twilio_contact?.name}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={10}>
              <Box display="flex">
                <Box>
                  <LabelValue label="Name" value={currentCheckIn.twilio_contact?.name} />
                  <LabelValue label="Company" value={currentCheckIn.twilio_contact?.company} />
                  <LabelValue label="Date" value={currentCheckIn.compliance_date} />
                </Box>
                <Box ml={2}>
                  <Tooltip title="Update Contact">
                    <IconButton
                      aria-label="update contact"
                      onClick={() => {
                        setShowCheckInDialog(false);
                        setShowTwilioContactDialog(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              <LabelValue label="Created" value={moment(currentCheckIn.created).format("LLLL")} />
              <LabelValue label="Check In" value={moment(currentCheckIn.checkin_timestamp).format("LLLL")} />
              <LabelValue label="Check Out" value={moment(currentCheckIn.checkout_timestamp).format("LLLL")} />
              <LabelValue label="Status" value={currentCheckIn.status} />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Box display="flex" justifyContent={{xs: "center", sm: "right"}}>
                {showCheckInDialog && (
                  <img src={`${pageData.qr_code_base_url}?data=${currentCheckIn.qr_code_url}`} height={130} />
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              {currentCheckIn.responses && (
                <>
                  <LabelValue label="Responses" value="" />
                  {Object.entries(currentCheckIn.responses).map(([key, value]) => {
                    const response = value as any;
                    return (
                      <div key={key} className={classes.response}>
                        <HtmlRender html={response.question} />{" "}
                        {key.startsWith("-") ? (
                          <strong style={{color: response.answer ? colorSuccess : colorError}}>
                            {unslugify(response.answer.toString())}
                          </strong>
                        ) : (
                          <strong style={{color: response.answer ? colorError : colorSuccess}}>
                            {unslugify(response.answer.toString())}
                          </strong>
                        )}
                      </div>
                    );
                  })}
                </>
              )}
              {currentCheckIn.scanned && (
                <>
                  <Box mt={2} />
                  <LabelValue label="Scanned" value={moment(currentCheckIn.scanned).calendar()} />
                  <LabelValue label="Scanned By" value={currentCheckIn.scanned_by?.full_name} />
                </>
              )}
            </Grid>
          </Grid>
          <Box mt={2} />
          <PaperPanel>
            <PaperPanel.Header isLoading={scanLogIsFetching}>
              <PaperPanel.Header.Title>Scan Log</PaperPanel.Header.Title>
            </PaperPanel.Header>
            <PaperPanel.Body>
              <ReactTableMui
                size="small"
                className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
                columns={[
                  {
                    Header: "Scanned By",
                    accessor: "scanned_by.full_name",
                  },
                  {
                    Header: "Scanned",
                    accessor: "created",
                    Cell: ({value}) => (value ? moment(value).format("LLLL") : ""),
                  },
                ]}
                initialState={{
                  sortBy: [
                    {
                      id: "created",
                      desc: true,
                    },
                  ],
                }}
                data={scanLog}
              />
            </PaperPanel.Body>
          </PaperPanel>

          {currentCheckIn.site_restricted && !currentCheckIn.deleted && (
            <>
              <Box mt={2} />
              <ColoredButton
                fullWidth
                color="error"
                variant="outlined"
                onClick={() => {
                  setShowResetCheckInConfirmation(true);
                }}
              >
                Reset Check In
              </ColoredButton>
            </>
          )}

          {user.is_superuser && (
            <>
              <Box mt={2} />
              <strong>Superuser Info</strong>
              <Grid container spacing={1}>
                <Grid item sm={6}>
                  <Link href={`/admin/twilioapp/wellnesscheckresult/${currentCheckIn.id}/change/`} target="_blank">
                    <FontAwesomeIcon icon={faExternalLink} /> Admin
                  </Link>
                </Grid>
                <Grid item sm={6}>
                  <Link
                    href={`/tools/wellness-checks/contact-tracing/?number=${currentCheckIn.twilio_contact?.number}&date=${currentCheckIn.compliance_date}`}
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faExternalLink} /> Contact Tracing
                  </Link>
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <FormActions.CloseButton onClick={() => setShowCheckInDialog(false)} variant="contained" color="primary" />
        </DialogActions>
      </Dialog>

      <Dialog open={showProjectQRCodeDialog} onClose={() => setShowProjectQRCodeDialog(false)} maxWidth="sm" fullWidth>
        <DialogTitle id="form-dialog-title">QR Code For Check In</DialogTitle>
        <DialogContent>
          {showProjectQRCodeDialog && (
            <Box display="flex" justifyContent="center">
              <img
                width={250}
                src={`${pageData.qr_code_base_url}?data=SMSTO:+${pageData.twilio_number}:${pageData.sms_keyword}`}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <FormActions.CloseButton
            onClick={() => setShowProjectQRCodeDialog(false)}
            variant="contained"
            color="primary"
          />
        </DialogActions>
      </Dialog>

      <TwilioContactDialogForm
        isOpen={showTwilioContactDialog}
        handleClose={() => setShowTwilioContactDialog(false)}
        initialValues={currentCheckIn?.twilio_contact || {}}
        onSubmit={(values) => {
          checkInsRPC
            .mutateAsync({
              action: `${currentCheckIn.twilio_contact.id}`,
              baseURL: `twilioapp/contacts/`,
              method: "PATCH",
              data: {
                name: values.name,
                company: values.company,
              },
            })
            .then(() => {
              setShowTwilioContactDialog(false);
              checkInsQuery.refetch();
            });
        }}
      />

      <PretaskPlanDialogForm
        projectId={project.id}
        userContact={userContact}
        // initialValues={currentPretaskPlan}
        initialValues={{
          ...currentPretaskPlan,
          audited_by: currentPretaskPlan.audited_by ?? userContact,
          audited_date: currentPretaskPlan.audited_date ?? moment().format("YYYY-MM-DD"),
        }}
        isOpen={showPretaskPlanDialog}
        handleClose={() => setShowPretaskPlanDialog(false)}
        // onSubmit={(values) => {
        //   setShowPretaskPlanDialog(false);
        //   updatePretaskPlan.mutateAsync(values);
        // }}
        onSubmit={(values) => {
          setAuditedFormValues(values);
          setAuditConfirmationIsOpen(true);
        }}
        onReviseResubmit={(values) => {
          setAuditedFormValues(values);
          setReviseResubmitDialogIsOpen(true);
        }}
        onUnlockPlan={() => {
          setShowPretaskPlanDialog(false);
          setShowUnlockDialog(true);
        }}
        canUnlock={canUnlock}
        isReadOnly={currentPretaskPlan.status !== "submitted"}
      />

      <ConfirmationDialog
        isOpen={reviseResubmitDialogIsOpen}
        onDeny={() => setReviseResubmitDialogIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Saving Changes...");
          pretaskPlanRPC
            .mutateAsync({action: `${currentPretaskPlan.id}/mark-revise-resubmit`, data: auditedFormValues})
            .then(() => {
              setReviseResubmitDialogIsOpen(false);
              setShowPretaskPlanDialog(false);
              blockUI.unblockUI();
            })
            .catch(() => {
              blockUI.unblockUI();
            });
        }}
      >
        You want to mark this Pretask Plan <strong>Revise Resubmit</strong>.{" "}
        {reviseResubmitContactEmail
          ? `${currentPretaskPlan?.twilio_contact?.name} will receive an email at ${reviseResubmitContactEmail} with a link to make corrections.`
          : `No email address found for ${currentPretaskPlan?.twilio_contact?.name}, please inform them manually.`}
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={auditConfirmationIsOpen}
        onDeny={() => setAuditConfirmationIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Saving Changes...");
          pretaskPlanRPC
            .mutateAsync({action: `${currentPretaskPlan.id}/mark-audited`, data: auditedFormValues})
            .then(() => {
              // updatePretaskPlan.mutateAsync(auditedFormValues).then(() => {
              setShowPretaskPlanDialog(false);
              setAuditConfirmationIsOpen(false);
              blockUI.unblockUI();
            });
        }}
        title="Audit this Pretask Plan?"
      >
        I have audited this Pretask Plan and certify that is has been completed correctly.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={showUnlockDialog}
        onDeny={() => setShowUnlockDialog(false)}
        onApprove={() => {
          blockUI.blockUI("Saving Changes...");
          pretaskPlanRPC
            .mutateAsync({action: `${currentPretaskPlan.id}/unlock-pretask-plan`})
            .then(() => {
              setShowUnlockDialog(false);
              blockUI.unblockUI();
            })
            .catch(() => {
              blockUI.unblockUI();
            });
        }}
        title="Unlock this Pretask Plan?"
      >
        Do you want to unlock this Pretask Plan for editing? This will allow the foreman to make changes.
      </ConfirmationDialog>
      <ConfirmationDialog
        isOpen={showResetCheckInConfirmation}
        onApprove={() => {
          blockUI.blockUI("Resetting Check-in...");
          setShowResetCheckInConfirmation(false);
          checkInsRPC
            .mutateAsync({
              action: "reset",
              baseURL: `projects/${project.id}/twilioapp/wellness-check-results/${currentCheckIn.id}/`,
            })
            .then(() => {
              blockUI.unblockUI();
              setShowCheckInDialog(false);
              checkInsQuery.refetch();
            })
            .catch(() => blockUI.unblockUI());
        }}
        onDeny={() => {
          setShowResetCheckInConfirmation(false);
        }}
      >
        Are you sure you want to reset check-in {currentCheckIn.compliance_date} - {currentCheckIn.twilio_contact?.name}
        ?
      </ConfirmationDialog>

      {/* <ConfirmationDialog
        onApprove={() => {
          setShowVerifyVaccineStatusConfirmation(false);
          blockUI.blockUI();
          if (currentCheckIn?.twilio_contact?.covid19_vaccine_status_is_verified) {
            checkInsRPC
              .mutateAsync({
                action: "revoke-covid19-vaccine-status",
                baseURL: `twilioapp/contacts/${currentCheckIn.twilio_contact.id}/`,
              })
              .then(() => {
                // setShowCheckInModal(false);
                blockUI.unblockUI();
                checkInsQuery.refetch();
              });
          } else {
            checkInsRPC
              .mutateAsync({
                action: "verify-covid19-vaccine-status",
                baseURL: `twilioapp/contacts/${currentCheckIn.twilio_contact.id}/`,
              })
              .then(() => {
                // setShowCheckInModal(false);
                blockUI.unblockUI();
                checkInsQuery.refetch();
              });
          }
        }}
        onDeny={() => setShowVerifyVaccineStatusConfirmation(false)}
        isOpen={showVerifyVaccineStatusConfirmation}
      >
        {currentCheckIn?.twilio_contact?.covid19_vaccine_status_is_verified
          ? `You want to revoke ${currentCheckIn?.twilio_contact?.name}'s vaccination verification.`
          : `By verifying this vaccination status for ${currentCheckIn?.twilio_contact?.name}, you are attesting to having viewed their physical vaccination card and completion of COVID-19 vaccinations.
              You will be permanently on record as verifying this vaccination. Do not verify this person if you have not seen their vaccination card.`}
      </ConfirmationDialog> */}

      <LegacyUILink href={`/projects/${project.id}/covid/checkin/legacy/?date=${date}`} mt={2} />

      {/* <Typography variant="h4" gutterBottom>
        Check Ins Query
      </Typography>
      <pre>{JSON.stringify(checkInsQuery, null, 2)}</pre>

      <Typography variant="h4" gutterBottom>
        Page Data Query
      </Typography>
      <pre>{JSON.stringify(pageData, null, 2)}</pre>

      <Typography variant="h4" gutterBottom>
        Project
      </Typography>
      <pre>{JSON.stringify(project, null, 2)}</pre> */}
    </>
  );
};

export default ProjectDailyCheckIns;

const CheckinPaperItem = (props) => {
  const {
    checkIn,
    project,
    isForeman,
    onClickContact,
    onClickForeman,
    // onClickVaccine,
    pretaskPlan,
    onClickPretaskPlan,
    sortByCompany,
    hasSafetyOrientation,
    ...rest
  } = props;
  const companyNameString = sortByCompany
    ? !checkIn.twilio_contact.fuzzy_company && checkIn.twilio_contact.company
    : (checkIn.twilio_contact?.fuzzy_company?.name ?? checkIn.twilio_contact.company);
  return (
    <PaperItem>
      <PaperItem.Body>
        <Grid container>
          <Grid item sm={2} xs={6}>
            {/* <Link style={{cursor: "pointer"}} underline="always" onClick={onClickContact}> */}

            <MuiNavLink
              style={{cursor: "pointer"}}
              underline="always"
              href={`/v2/projects/${project.id}/check-ins/${checkIn.id}/`}
            >
              {checkIn?.twilio_contact?.name || (
                <Tooltip title="No Name Registered / Checkin Incomplete">
                  <span>Unregistered</span>
                </Tooltip>
              )}
            </MuiNavLink>
          </Grid>
          {companyNameString && (
            <Grid item sm={3} xs={6}>
              {companyNameString}
            </Grid>
          )}

          <Grid item xs={6} sm={3}>
            <Box display="flex" alignItems="center">
              <Box>
                <Tooltip title={isForeman ? "Remove Foreman" : "Make Foreman"}>
                  <span>
                    <FontAwesomeIcon
                      style={{cursor: "pointer"}}
                      icon={faHardHat}
                      color={isForeman ? colorSuccess : colorMutedText}
                      onClick={onClickForeman}
                    />
                  </span>
                </Tooltip>
              </Box>
              {pretaskPlan && (
                <>
                  <Box ml={1}>
                    <StatusIcon status={pretaskPlan.status} tooltip={pretaskPlan.status_display} showTooltip />
                  </Box>
                  <Box ml={1}>
                    <Link
                      style={{cursor: "pointer"}}
                      underline="always"
                      target="_blank"
                      href={`/reports2/projects/${project.id}/pretask-plan/${pretaskPlan.id}/`}
                    >
                      {/* <FontAwesomeIcon icon={faFilePdf} />  */}
                      View Pretask Plan
                    </Link>
                  </Box>
                </>
              )}
            </Box>
          </Grid>

          <Grid item xs={6} sm={2}>
            {checkIn.status}
          </Grid>
          <Grid item container xs={12} sm={3}>
            <Grid item xs={6}>
              <Tooltip title="Check In">
                <span>
                  <span>
                    {checkIn.checkin_timestamp
                      ? moment.tz(checkIn.checkin_timestamp, project.timezone).format("hh:mm A z")
                      : ""}
                  </span>
                </span>
              </Tooltip>
            </Grid>

            <Grid item xs={5}>
              <Tooltip title="Check Out">
                <span>
                  {checkIn.checkout_timestamp
                    ? moment.tz(checkIn.checkout_timestamp, project.timezone).format("hh:mm A z")
                    : ""}
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </PaperItem.Body>
      <PaperItem.Right minWidth={50}>
        <>
          {hasSafetyOrientation ? (
            <Tooltip title="Safety Orientation Completed">
              <span>
                <FontAwesomeIcon icon={faCircleCheck} color={colorSuccess} />
              </span>
            </Tooltip>
          ) : (
            <Tooltip title="Safety Orientation Not Completed">
              <span>
                <FontAwesomeIcon icon={faCircleXmark} color={colorError} />
              </span>
            </Tooltip>
          )}
          {checkIn?.deleted && (
            <Box ml={0.5} component="span">
              <Tooltip title={`Deleted by ${checkIn?.deleted_by?.full_name} on ${checkIn?.deleted}`}>
                <span>
                  <FontAwesomeIcon icon={faTrash} color={colorError} />
                </span>
              </Tooltip>
            </Box>
          )}
        </>
      </PaperItem.Right>
    </PaperItem>
  );
};
